import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Arrow from '../shared/Arrow';
import styles from './bannerCareers.module.scss';

export class PureBannerCareers extends Component {

  render() {
    const {data, render_arrow, scrollToTopContent} = this.props;
    const {banner, image} = data;
    const {frontmatter} = banner;
    const {button_text, banner_image_alt, header_description, page_header} = frontmatter;

    return (
      <section role='banner'>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          className={'d-none d-md-block'}
        />
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          style={{height: '760px'}}
          objectFit="cover"
          objectPosition="50%"
          className={'d-block d-md-none'}
        />
        <div className={styles.title}>
          <h1 className={styles.header}>{page_header}</h1>
          <p className={'d-none d-xl-block' + ' ' + styles.descriptionLarge}>
            {header_description}
          </p>
          <a className={styles.button} href='#opportunities' id="careers-btn">{button_text}</a>
        </div>
        {/* header description for XS-LG screens within VisionCareers.js */}
        <Arrow
          arrow_color='#FFFFFF'
          href="#careers-top-content"
          scrollToTopContent={scrollToTopContent}
          render_arrow={render_arrow}
        />
      </section>
    );
  }
}

export const BannerCareers = props => (
  <StaticQuery
    query={graphql`{
  image: file(relativePath: {eq: "images/forest-path.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: markdownRemark(frontmatter: {path: {eq: "/careers-banner/"}}) {
    html
    frontmatter {
      banner_image_alt
      button_text
      header_description
      page_header
    }
  }
}
`}
    render={data => <PureBannerCareers {...props} data={data} />}
  />
);

export default BannerCareers;