import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';

import {Carousel} from '../shared/Carousel';
import styles from './benefitsCareers.module.scss';

export class PureBenefitsCareers extends Component {

  render() {
    const {data} = this.props;
    const {content, image_1, image_2, image_3, image_4} = data;
    const {frontmatter, html} = content;
    const {
      intro,
      header,
      caption_1,
      alt_1,
      id_1,
      caption_2,
      alt_2,
      id_2,
      caption_3,
      alt_3,
      id_3,
      caption_4,
      alt_4,
      id_4
    } = frontmatter;

    const carousel_contents = [
      {
        image: image_1.childImageSharp.gatsbyImageData,
        caption: caption_1,
        alt: alt_1,
        id: id_1
      },
      {
        image: image_2.childImageSharp.gatsbyImageData,
        caption: caption_2,
        alt: alt_2,
        id: id_2
      },
      {
        image: image_3.childImageSharp.gatsbyImageData,
        caption: caption_3,
        alt: alt_3,
        id: id_3
      },
      {
        image: image_4.childImageSharp.gatsbyImageData,
        caption: caption_4,
        alt: alt_4,
        id: id_4
      }
    ];

    return (
      <section aria-label='benefits' className={styles.benefits} id='benefits'>
        <div className={'offset-md-2 col-md-8 col-xl-4' + ' ' + styles.header}>
          <p className={styles.intro}>{intro}</p>
          <h2 className={styles.h2}>{header}</h2>
        </div>
        <div className={'offset-md-2 col-md-8' + ' ' + styles.content}>
          <div className={styles.carousel} aria-label='image carousel'>
            <Carousel carousel_contents={carousel_contents} tall_caption={false}/>
          </div>
          <div className={styles.paragraph} dangerouslySetInnerHTML={{__html: html}} />
        </div>
      </section>
    );
  }
}

export const BenefitsCareers = props => (
  <StaticQuery
    query={graphql`{
  content: markdownRemark(frontmatter: {path: {eq: "/careers-benefits/"}}) {
    html
    frontmatter {
      intro
      header
      caption_1
      alt_1
      id_1
      caption_2
      alt_2
      id_2
      caption_3
      alt_3
      id_3
      caption_4
      alt_4
      id_4
    }
  }
  image_1: file(relativePath: {eq: "images/careers/teambuilding-outdoors.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_2: file(relativePath: {eq: "images/careers/home-improvement-day.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_3: file(relativePath: {eq: "images/careers/camaraderie-building.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_4: file(relativePath: {eq: "images/careers/rex-bike-careers.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => <PureBenefitsCareers {...props} data={data} />}
  />
);

export default BenefitsCareers;