import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import styles from './opportunitiesCareers.module.scss';

export class PureOpportunitiesCareers extends Component {


  renderHeader() {
    const {data} = this.props;
    const {content} = data;
    const {frontmatter} = content;
    const {job_postings_header} = frontmatter;
    if (this.props.data.allMarkdownRemark.edges.every(({node}) => node.frontmatter.expired == 'true')) return null;
    return (
      <h2 className={styles.openingsHeader}>{job_postings_header}</h2>
    );
  }

  renderPositions() {
    if (this.props.data.allMarkdownRemark.totalCount == 0) return null;
    const open_positions = this.props.data.allMarkdownRemark.edges.map(({node}) => {
      if (node.frontmatter.expired != 'true') {
        return (
          <div key={node.id}>
            <Link to={node.fields.slug} className={styles.link}>
              {node.frontmatter.article_title}
            </Link>
          </div>
        );
      }
    });
    return open_positions;
  }

  render() {
    const {data} = this.props;
    const {content} = data;
    const {frontmatter, html} = content;
    const {header, intro} = frontmatter;
    return (
      <section aria-label='opportunities' className={styles.content}>
        <div className={'offset-md-2 col-md-8' + ' ' + styles.opportunities}>
          <div>
            <p className={styles.intro} >{intro}</p>
            <h2 className={styles.h2} id='opportunities'>{header}</h2>
            <div className={styles.paragraph} dangerouslySetInnerHTML={{__html: html}} />
            {this.renderHeader()}
            {this.renderPositions()}
          </div>
        </div>
      </section>
    );
  }
}

export const OpportunitiesCareers = props => (
  <StaticQuery
    query={graphql`
      query {
        content: markdownRemark(frontmatter: { path: { eq: "/careers-opportunities/" } }) {
          html
          frontmatter {
            intro
            header
            job_postings_header
          }
        }
        allMarkdownRemark (
          filter: {
            fileAbsolutePath: { regex: "/job-postings/" }
          },
          sort: {fields: frontmatter___article_title}
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                article_title
                expired
              }
              fields {
                slug
              }
            }
          }
        }
      }
      
    `}
    render={data => <PureOpportunitiesCareers {...props} data={data} />}
  />
);


export default OpportunitiesCareers;