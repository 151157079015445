import React, {Component} from 'react';
import {GatsbyImage} from 'gatsby-plugin-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './carousel.module.scss';

export class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayed: 0
    };
  }

  shiftCarousel(direction) {
    const {displayed} = this.state;
    const {carousel_contents} = this.props;
    const last_image = carousel_contents.length - 1;

    if (direction == 'left') {
      if (displayed == 0)
        this.setState({displayed: last_image});
      else
        this.setState({displayed: displayed - 1});
    }
    if (direction == 'right') {
      if (displayed == last_image)
        this.setState({displayed: 0});
      else
        this.setState({displayed: displayed + 1});
    }

  }

  renderControls() {
    const {carousel_contents} = this.props;
    const {displayed} = this.state;

    const controls = carousel_contents.map((image, index) => {
      return (
        <li
          key={index}
          tabIndex={0}
          onClick={() => this.setState({displayed: index})}
          onFocus={() => this.setState({displayed: index})}
          aria-label={`image-${index + 1}-of-${carousel_contents.length}`}
          className={styles.iconListItem}
          id={image.id}
          data-testid={image.id}
        >
          <FontAwesomeIcon
            icon={['fal', 'square-full']}
            className={displayed == index ? styles.squareBackground : styles.square}
          />
        </li>
      );
    });
    return controls;
  }

  render() {
    const {displayed} = this.state;
    const {carousel_contents, tall_caption} = this.props;
    return (
      <>
        <div className={styles.imageRow}>
          <FontAwesomeIcon
            icon={['fal', 'chevron-left']}
            className={'d-block d-md-none' + ' ' + styles.chevronLeft}
            onClick={() => this.shiftCarousel('left')}
          />
          <GatsbyImage
            image={carousel_contents[`${displayed}`].image}
            className={styles.image}
            alt={carousel_contents[`${displayed}`].alt}
          />
          <FontAwesomeIcon
            icon={['fal', 'chevron-right']}
            className={'d-block d-md-none' + ' ' + styles.chevronRight}
            onClick={() => this.shiftCarousel('right')}
          />
        </div>
        <div className={tall_caption ? styles.captionHeightTall : null}>
          <p className={styles.caption} id='caption'>{this.props.carousel_contents[`${displayed}`].caption}</p>
        </div>
        <ul className={'row' + ' ' + styles.iconList}>
          {this.renderControls()}
        </ul>
      </>
    );
  }
}

export default Carousel;