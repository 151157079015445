import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';

import styles from './valuesCareers.module.scss';

export class PureValuesCareers extends Component {



  renderValuesRow() {
    const {data} = this.props;
    const {transparency, empathetic_candor, autonomy, unity, balance, humility} = data;

    const icon_array = this.props.data.icons.edges;

    const values = [transparency, empathetic_candor, autonomy, unity, balance, humility];

    const array_of_values = values.map((value) => {
      const {frontmatter} = value;
      const {description, icon_alt, icon_name, title} = frontmatter;

      const getIcon = () => {
        const matching_icon = icon_array.find(icon => {
          if (icon.node.publicURL.includes(icon_name))
            return icon;
        });
        return matching_icon.node.publicURL;
      };

      return (
        <li key={title} className={styles.valueListItem}>
          <img
            className={styles.customIcon}
            src={getIcon()}
            alt={icon_alt}
          />
          <h3 className={styles.valueTitle}>{title}</h3>
          <p className={styles.valueDescription}>{description}</p>
        </li>
      );
    });
    return array_of_values;
  }

  render() {
    const {data} = this.props;
    const {main_content} = data;
    const {frontmatter} = main_content;
    const {description, header, intro} = frontmatter;

    return (
      <section aria-label='company values' className={styles.valuesSection}>
        <div className={'offset-md-2 col-md-8' + ' ' + styles.header}>
          <p className={styles.intro}>{intro}</p>
          <h2 className={styles.h2}>{header}</h2>
          <p className={styles.paragraph}>{description}</p>
        </div>
        <div className={'offset-md-2 col-md-8'}>
          <ul className={'row' + ' ' + styles.valueList}>
            {this.renderValuesRow()}
          </ul>
        </div>
      </section>
    );
  }
}

export const ValuesCareers = props => (
  <StaticQuery
    query={graphql`
      query {
        main_content: markdownRemark(frontmatter: { path: { eq: "/careers-values/" } }) {
          html
          frontmatter {
            description
            header
            intro
          }
        }
        transparency: markdownRemark(frontmatter: { path: { eq: "/transparency/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        empathetic_candor: markdownRemark(frontmatter: { path: { eq: "/empathetic-candor/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        autonomy: markdownRemark(frontmatter: { path: { eq: "/autonomy/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        unity: markdownRemark(frontmatter: { path: { eq: "/unity/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        balance: markdownRemark(frontmatter: { path: { eq: "/balance/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        humility: markdownRemark(frontmatter: { path: { eq: "/humility/" } }) {
          frontmatter {
            description
            icon_alt
            icon_name
            title
          }
        }
        icons: allFile(
          filter: {relativePath: {regex: "/custom-icons\/values/"}},
          ) {
            edges {
              node {
                publicURL
              }
            }
        }
      }
    `}
    render={data => <PureValuesCareers {...props} data={data} />}
  />
);

export default ValuesCareers;