import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';

import {Carousel} from '../shared/Carousel';
import styles from './visionCareers.module.scss';

export class PureVisionCareers extends Component {

  render() {
    const {data} = this.props;
    const {banner, content, image_1, image_2, image_3, image_4, image_5} = data;
    const {
      intro,
      header,
      caption_1,
      alt_1,
      id_1,
      caption_2,
      alt_2,
      id_2,
      caption_3,
      alt_3,
      id_3,
      caption_4,
      alt_4,
      id_4,
      caption_5,
      alt_5,
      id_5
    } = content.frontmatter;

    const carousel_contents = [
      {
        image: image_1.childImageSharp.gatsbyImageData,
        caption: caption_1,
        alt: alt_1,
        id: id_1
      },
      {
        image: image_2.childImageSharp.gatsbyImageData,
        caption: caption_2,
        alt: alt_2,
        id: id_2
      },
      {
        image: image_3.childImageSharp.gatsbyImageData,
        caption: caption_3,
        alt: alt_3,
        id: id_3
      },
      {
        image: image_4.childImageSharp.gatsbyImageData,
        caption: caption_4,
        alt: alt_4,
        id: id_4
      },
      {
        image: image_5.childImageSharp.gatsbyImageData,
        caption: caption_5,
        alt: alt_5,
        id: id_5
      }
    ];

    return (
      <section className={'offset-md-2 col-md-8' + ' ' + styles.vision} aria-label='company vision' id="vision">
        {/* header description for XL screens within BannerCareers.js */}
        <div className={'d-block d-xl-none'}>
          <div className={styles.noPadding}>
            <p className={styles.descriptionSmall}>
              {banner.frontmatter.header_description}
            </p>
          </div>
        </div>
        <div className={styles.visionRow}>
          <div className={'col-lg-7 offset-lg-1 order-lg-12' + ' ' + styles.content}>
            <p className={styles.intro}>{intro}</p>
            <h2 className={styles.h2}>{header}</h2>
            <div className={styles.paragraph} dangerouslySetInnerHTML={{__html: content.html}} />
          </div>
          <div className={'col-lg-4' + ' ' + styles.carousel}>
            <Carousel carousel_contents={carousel_contents} tall_caption={true} aria-label='image carousel'/>
          </div>
        </div>
      </section>
    );
  }
}

export const VisionCareers = props => (
  <StaticQuery
    query={graphql`{
  banner: markdownRemark(frontmatter: {path: {eq: "/careers-banner/"}}) {
    html
    frontmatter {
      header_description
    }
  }
  content: markdownRemark(frontmatter: {path: {eq: "/careers-vision/"}}) {
    html
    frontmatter {
      intro
      header
      caption_1
      alt_1
      id_1
      caption_2
      alt_2
      id_2
      caption_3
      alt_3
      id_3
      caption_4
      alt_4
      id_4
      caption_5
      alt_5
      id_5
    }
  }
  image_1: file(relativePath: {eq: "images/careers/collaborating-team-members.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_2: file(relativePath: {eq: "images/careers/working-with-users.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_3: file(relativePath: {eq: "images/careers/empathetic-discussion.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_4: file(
    relativePath: {eq: "images/careers/working-in-person.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  image_5: file(relativePath: {eq: "images/careers/client-meeting.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => <PureVisionCareers {...props} data={data} />}
  />
);

export default VisionCareers;