import React, {Component} from 'react';
import {graphql} from 'gatsby';

import SEO from '../components/shared/SEO';
import {Navbar} from '../components/shared/Navbar';
import BannerCareers from '../components/careerspage/BannerCareers';
import VisionCareers from '../components/careerspage/VisionCareers';
import BenefitsCareers from '../components/careerspage/BenefitsCareers';
import OpportunitiesCareers from '../components/careerspage/OpportunitiesCareers';
import Footer from '../components/shared/Footer';
import registerIcons from '../styles/registerIcons';
import ValuesCareers from '../components/careerspage/ValuesCareers';
import styles from './careers.module.scss';
import createObserver from '../utils/IntersectionObserver';

// font awesome library needs to be created via entry point of app
// so needed here in case of refresh on this screen
registerIcons();

class CareersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    render_arrow: false
    };

    this.top_content = React.createRef();
    this.scrollToTopContent = this.scrollToTopContent.bind(this);
    this.setComponentState = this.setComponentState.bind(this);
  }

  scrollToTopContent() {
    this.top_content.current.scrollIntoView({behavior: 'smooth'});
  }

  setComponentState(key, value) {
    this.setState({[key]: value});
  }

  componentDidMount() {
    // show arrow only if browser can use IntersectionObserver
    if (typeof window.IntersectionObserver != 'undefined') {
      const ratio = 0.05;
      const threshold = [0, 0.05, 0.2, 0.4, 0.6, 0.8, 1];
      const observer = createObserver(ratio, this.setComponentState, threshold);
      if (this.top_content && this.top_content.current)
        observer.observe(this.top_content.current);
    }
  }

  render() {
    const {data} = this.props;
    const {seo} = data;
    const {frontmatter} = seo;
    const {page_description, page_title} = frontmatter;

    return (
      <div className={styles.careers}>
        <header>
          <SEO title={page_title} description={page_description} />
          <Navbar />
        </header>
        <main>
          <BannerCareers render_arrow={this.state.render_arrow} scrollToTopContent={this.scrollToTopContent}/>
          <div ref={this.top_content}>
            <VisionCareers />
          </div>
          <ValuesCareers />
          <BenefitsCareers />
          <OpportunitiesCareers />
        </main>
        <Footer />
      </div>
    );
  }
}


export const pageQuery = graphql`
  query {
    seo: markdownRemark(frontmatter: { path: { eq: "/careers-page-seo/" } }) {
      html
      frontmatter {
        page_title
        page_description
      }
    }
  }
`;

export default CareersPage;